import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Link from '../components/Link'
import Section from '../components/Section'

import Typography from '../components/Typography'
import Hero from '../components/EnrollmentHero'
import Layout from '../layouts/index'
import Enrollment from '../components/Enrollment'
import heroImage from '../images/enrollment-hero.jpg'

const StyledEnrollment = styled(Enrollment)`
  min-height: 600px;
`

const ResidentialPlans = () => {
  let isIL = false
  if (typeof window !== 'undefined') {
    const parts = window.location.hash.split('/')
    isIL = parts.includes('IL')
  }
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>Residential Electricity Plans | Just Energy</title>
        <meta
          name="description"
          content="Just Energy residential electricity plans cater to your specific needs
        and lifestyle to ensure you have an affordable energy plan supplying
        your home with electricity."
        />
      </Helmet>
      <Layout>
        <Hero image={heroImage}>
          <Typography variant="h1">Residential Electricity Plans</Typography>
        </Hero>
        {isIL && (
          <Section style={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <p>
              To find the price to compare, click{' '}
              <Link to="/locations/illinois">here.</Link>
            </p>
          </Section>
        )}
        <main>
          <StyledEnrollment basePath="/residential-plans" />
        </main>
      </Layout>
    </>
  )
}

export default ResidentialPlans
